import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Logo } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/logo",
  "title": "Logo",
  "navTitle": "Logo"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Logo language="fi" size="medium" mdxType="Logo" />
    </PlaygroundPreview>
    <p>{`The logo is used to identify the site or application as an official City of Helsinki service.
See the `}<InternalLink size="M" href="/foundation/visual-assets/logo" mdxType="InternalLink">{`logo visual asset documentation`}</InternalLink>{` for more information about principles of using the City of Helsinki logo.`}</p>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`Logo is automatically included in HDS Navigation and HDS Footer (not yet released) components. `}<strong parentName="li">{`Use the Logo component sparingly in other parts of the service.`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`You should include the service name next to the logo.`}</strong>{` This helps the user more easily identify which city service they are currently using.`}</li>
      <li parentName="ul">{`HDS Logo component includes translations for both Finnish and Swedish. `}<strong parentName="li">{`Use translation depending on the locale the user has chosen.`}</strong>{` Swedish and Scandinavian languages use the `}<inlineCode parentName="li">{`sv`}</inlineCode>{` variant while other languages use `}<inlineCode parentName="li">{`fi`}</inlineCode>{` variant.`}</li>
      <li parentName="ul">{`The Logo component should act as a link to the main page of the service.`}</li>
      <li parentName="ul">{`This component is solely for the City of Helsinki logo. Do not use this component for logos of other parties.`}</li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The Helsinki logo component is offered in three (3) languages.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Logo language="fi" size="medium" style={{
        marginRight: 'var(--spacing-xs)'
      }} mdxType="Logo" />
  <Logo language="sv" size="medium" style={{
        marginRight: 'var(--spacing-xs)'
      }} mdxType="Logo" />
  <Logo language="ru" size="medium" mdxType="Logo" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "sizes",
      "style": {
        "position": "relative"
      }
    }}>{`Sizes`}<a parentName="h4" {...{
        "href": "#sizes",
        "aria-label": "sizes permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The component includes three (3) size variants to fit the context.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Logo language="fi" size="small" style={{
        marginRight: 'var(--spacing-xs)'
      }} mdxType="Logo" />
  <Logo language="fi" size="medium" style={{
        marginRight: 'var(--spacing-xs)'
      }} mdxType="Logo" />
  <Logo language="fi" size="large" mdxType="Logo" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      